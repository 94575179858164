import { createApp, ComponentPublicInstance } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/tailwind.css";
import Components from "./components/index";
import Icons from "./data/providers/icon-provider";
import { loadPreferredLanguageAsync } from "./data/providers/localization-provider";
import { clickoutside } from "@/utilities/events";
import { authentication } from "@/data/providers/authentication-provider";

let app: ComponentPublicInstance | undefined;

function startApp(): void {
	app = createApp(App)
		.use(router)
		.use(Components)
		.use(Icons)
		.directive("click-outside", clickoutside)
		.mount("#app");
}

authentication.auth.onAuthStateChanged(() => {
	if (!app) {
		loadPreferredLanguageAsync().finally(startApp);
	}
});