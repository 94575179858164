export enum EditorView {
	Split,
	Blocks,
	Code
}

export interface EditorButton {
	text: string;
	color: string;
	icon?: string;
	visible?: boolean;
	action: VoidFunction;
}

export enum EditorButtons {
	Run,
	Save,
	DownloadHex,
	Share,
	Popout
}

export interface EditorTab {
	title: string;
	icon: string;
	action: Function | void;
	active: EditorView;
}