import { Platform } from "@/data/providers/platform-provider";
import { EditorButtons } from "@/views/editor/editor-type";

export const raspberrypi: Platform = {
	name: "Raspberry Pi",
	key: "RPi",
	image: "/assets/images/platforms/raspberrypi.png",
	icon: "/assets/images/platforms/raspberrypi-icon.svg",
	color: "plum",
	actions: [EditorButtons.Save]
};