import clb from "clb";

export class EbButton {
	public variants: Function = clb({
		defaults: {
			type: "basic",
			size: "sm"
		},
		variants: {
			type: {
				basic: "flex items-center justify-center flex-none text-sm font-medium transition-all border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 space-x-2 dark:ring-offset-gray-900",
				rounded: "flex items-center p-2 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all dark:ring-offset-gray-900"
			},
			size: {
				sm: "px-4 py-2",
				lg: "px-5 py-3",
				none: ""
			},
			color: {
				pink: "bg-pink-500 hover:bg-pink-600 focus:ring-pink-500 text-white border-transparent",
				red: "bg-red-500 hover:bg-red-600 focus:ring-red-500 text-white border-transparent",
				green: "bg-green-400 hover:bg-green-500 focus:ring-green-400 text-gray-900 border-transparent",
				gray: "border-gray-300 focus:ring-gray-300 bg-white hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-900"
			}
		}
	})
}