<template>
	<div class="flex h-screen overflow-hidden bg-gray-50 dark:bg-gray-900">
		<EbNav :options="defaultNavigation" />
		<div class="flex flex-col flex-1 overflow-hidden">
			<EbHeader />
			<div class="w-full h-full py-8 overflow-y-auto sm:px-6 lg:px-8">
				<slot />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { defaultNavigation } from "@/data/providers/navigation-provider";

export default defineComponent({
	name: "DefaultLayout",
	setup() {
		return { defaultNavigation };
	}
});
</script>
