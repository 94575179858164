<template>
	<EbPageHeader :title="view.getText('projects')">
		<div class="flex space-x-4">
			<EbButton
				color="gray"
				icon="DocumentAddIcon"
				text="Import"
			/>
			<EbButton
				color="pink"
				icon="PlusIcon"
				type="rounded"
				size="none"
				@click="this.$router.push({path: '/editor'})"
			/>
		</div>
	</EbPageHeader>
	<div class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8">
		<EbThumbnailCardSkeleton
			v-for="card in 8"
			:key="card"
			:visible="view.state.isBusy"
		/>
		
		<EbThumbnailCard
			v-for="project in view.state.projects"
			:key="project"
			:title="getLocalDataForFile(project).shortTitle" 
			:subtitle="getLocalDataForFile(project).platform?.name"
			:gradient-image="getLocalDataForFile(project).platform?.icon"
			thumbnail-gradient
			:dropdown-options="view.projectDropdown(project)"
			@cardClick="view.openProject(project)"
		/>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { ProjectsModel } from "./projects-model";
import { getLocalDataForFile } from "@/data/providers/files-provider";

export default defineComponent({
	name: View.Projects,
	setup() {
		const view: ProjectsModel = new ProjectsModel();

		onMounted(() => {
			view.init();
		});

		return { view, getLocalDataForFile };
	}
});
</script>
