<template>
	<!-- Platform Selector -->
	<div
		v-if="!state.platform"
		class="flex items-center justify-center w-full h-full p-8"
	>
		<div class="w-full space-y-8">
			<div>
				<h1 class="text-5xl font-extrabold text-gray-900 sm:text-center dark:text-white">
					{{ view.getText('get-started') }}
				</h1>
				<p class="mt-5 text-xl text-gray-500 sm:text-center dark:text-gray-400">
					{{ view.getText('choose-a-platform') }}
				</p>
			</div>
			<EbRadioGroup
				v-model="view.state.selected"
				:options="platforms"
				name="platforms"
			/>
			<div
				class="flex justify-center w-full space-x-4"
				:class="!view.state.selected ? 'invisible' : ''"
			>
				<EbInput
					v-model="state.filename"
					label="Filename"
					class="w-80"
				/>
				<EbButton
					:text="view.getText('start-coding')"
					color="pink"
					@click="view.setGlobalPlatform()"
				/>
			</div>
		</div>
	</div>

	<!-- Editor -->
	<div
		v-else
		class="flex flex-col w-full h-full"
	>
		<EbToolbar />
		<div class="flex flex-col w-full h-full px-6 pb-6">
			<div class="flex justify-between w-full space-x-4">
				<div class="flex pt-4 space-x-4">
					<EbTab
						v-for="tab in view.editorTabs"
						:key="tab"
						:title="tab.title"
						:icon="tab.icon"
						:active="view.state.view === tab.active"
						@click="tab.action"
					/>
				</div>
				<div
					v-if="state.platform.actions && !state.assignmentModeActive && !state.assignmentViewModeActive"
					class="flex pb-2.5 pt-3 space-x-4"
				>
					<EbButton
						v-for="button in state.platform.actions"
						:key="button"
						:text="view.getEditorButton(button).text"
						:color="view.getEditorButton(button).color"
						:icon="view.getEditorButton(button).icon"
						@click="view.getEditorButton(button).action()"
					/>
				</div>
				<div
					v-if="state.assignmentModeActive && !state.assignmentViewModeActive"
					class="flex pb-2.5 pt-3 space-x-4"
				>
					<EbButton
						text="Back to assignment"
						color="gray"
						icon="ArrowLeftIcon"
						@click="this.$router.back()"
					/>
					<EbButton
						text="Save Assignment"
						color="pink"
						icon="SaveIcon"
						@click="view.saveToAssignment()"
					/>
				</div>
				<div
					v-if="state.assignmentViewModeActive"
					class="flex pb-2.5 pt-3 space-x-4"
				>
					<EbButton
						text="Back to assignment"
						color="gray"
						icon="ArrowLeftIcon"
						@click="this.$router.back()"
					/>
				</div>
			</div>
			
			<div class="flex w-full h-full">
				<div
					v-show="view.state.isBlockEditorActive"
					class="w-full h-full bg-white border dark:border-gray-700 dark:bg-gray-800"
				>
					<Blockly />
				</div>
				<iframe
					v-if="view.state.isHTMLPreviewActive"
					class="w-full h-full max-w-sm ml-4 bg-white border sm:max-w-md dark:border-gray-700"
					:srcdoc="code"
				/>
				<div
					v-if="view.state.isCodeWindowActive"
					class="w-full h-full bg-white border dark:border-gray-700"
					:class="view.state.view === EditorView.Code ? '' : 'sm:max-w-md max-w-sm ml-4'"
				>
					<codemirror
						:value="code"
						:options="{
							mode: 'python',
							lineNumbers: true,
							theme: 'edublocks',
							readOnly: true
						}"
						class="w-full h-full"
					/>
				</div>
				<EbTrinket
					v-if="view.state.isTrinketWindowActive"
					class="w-full h-full max-w-sm ml-4 bg-white border sm:max-w-md"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { platforms } from "@/data/providers/platform-provider";
import { View } from "@/views/constants";
import { state } from "@/data/providers/global-provider";
import { EditorModel } from "./editor-model";
import { code } from "./editor-state";
import Blockly from "@/components/Blockly/Blockly.vue";
import { EditorView, EditorButtons } from "./editor-type";
import { NavigationGuardNext, onBeforeRouteLeave, RouteLocationNormalized } from "vue-router";
import { codemirror } from "vue-codemirror-lite";
import "codemirror/mode/python/python";
import "@/assets/codemirror.css";

export default defineComponent({
	name: View.Editor,
	components: {
		Blockly,
		codemirror
	},
	setup() {
		const view: EditorModel = new EditorModel();

		onMounted(() => {
			view.switchView(EditorView.Split);
		});

		onBeforeRouteLeave((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
			if (!view.state.isSaved && state.platform) {
				if (confirm("Are you sure you want to leave without saving?")) {
					window.onbeforeunload = null;
					view.clear();
					state.assignmentModeActive = false;
					state.assignmentViewModeActive = false;
					next();
				}
			}
			else {
				view.clear();
				state.assignmentModeActive = false;
				state.assignmentViewModeActive = false;
				next();
			}
		});

		return { view, platforms, state, EditorView, code, EditorButtons };
	}
});
</script>
