<template>
	<div
		v-if="view.state.assignment"
		class="flex flex-col w-full h-full"
	>	
		<div class="flex w-full h-full">
			<div class="w-full h-full">
				<EbPageHeader
					:title="view.state.assignment.data.title"
					no-border
					back-arrow
					@arrowClick="this.$router.push({path: `/classroom/${view.state.classroom?.id}`})"
				/> 
				<EbSelector
					:items="view.getTabs()"
					:selected="view.state.selected"
				/>
				<div
					v-if="view.state.selected === 'submission'"
					class="bg-white border"
				>
					<div class="px-4 py-5 sm:px-6">
						<h2
							id="applicant-information-title"
							class="text-lg font-medium leading-6 text-gray-900"
						>
							Submission
						</h2>
						<p class="max-w-2xl mt-1 text-sm text-gray-500">
							Information about your submission for this assignment
						</p>
					</div>
					<div class="px-4 py-5 border-t border-gray-200 sm:px-6">
						<div
							v-if="!view.state.submission?.data.markedByTeacher"
							class="flex items-center justify-center p-6 space-x-6 border rounded-md"
						>
							<div class="flex items-center justify-center w-16 h-16 bg-red-200 rounded-full">
								<XIcon class="w-8 h-8 text-red-500" />
							</div>
							<div>
								<h1 class="text-lg font-bold text-gray-900">
									Marking in progress
								</h1>
								<h1 class="text-gray-500">
									Your teacher has not yet marked your work
								</h1>
							</div>
						</div>
						
						<div
							v-else
							class="flex items-center justify-center p-6 space-x-6 border rounded-md"
						>
							<div class="flex items-center justify-center w-16 h-16 bg-green-100 rounded-full">
								<CheckIcon class="w-8 h-8 text-green-500" />
							</div>
							<div>
								<h1 class="text-lg font-bold text-gray-900">
									Marked!
								</h1>
								<h1 class="text-gray-500">
									Your teacher has marked your work.
								</h1>
							</div>
						</div>

						<dl
							v-if="view.state.submission?.data.markedByTeacher"
							class="grid grid-cols-1 mt-6 gap-x-4 gap-y-8 sm:grid-cols-2"
						>
							<div class="sm:col-span-1">
								<dt class="text-sm font-medium text-gray-500">
									Grade
								</dt>
								<dd class="mt-1 text-sm text-gray-900">
									{{ view.state.submission?.data.grade }}
								</dd>
							</div>
							<div class="sm:col-span-1">
								<dt class="text-sm font-medium text-gray-500">
									Marks
								</dt>
								<dd class="mt-1 text-sm text-gray-900">
									{{ view.state.submission?.data.marks }}/{{ view.state.assignment?.data.marks }}
								</dd>
							</div>
							<div class="sm:col-span-2">
								<dt class="text-sm font-medium text-gray-500">
									Comments from your teacher
								</dt>
								<dd class="mt-1 text-sm text-gray-900 whitespace-pre-wrap">
									{{ view.state.submission?.data.teacherComments ? view.state.submission?.data.teacherComments : 'No comments from teacher.' }}
								</dd>
							</div>
						</dl>
					</div>
				</div>

				<div
					v-if="view.state.selected === 'assignment-details'"
					class="bg-white border dark:bg-gray-800 dark:border-gray-800"
				>
					<div class="px-4 py-5 sm:px-6">
						<h2
							id="applicant-information-title"
							class="text-lg font-medium leading-6 text-gray-900 dark:text-white"
						>
							Assignment Details
						</h2>
						<p class="max-w-2xl mt-1 text-sm text-gray-500 dark:text-gray-400">
							Information about {{ view.state.assignment.data.title }}
						</p>
					</div>
					<div class="px-4 py-5 border-t border-gray-200 sm:px-6 dark:border-gray-900">
						<dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
							<div class="sm:col-span-1">
								<dt class="text-sm font-medium text-gray-500 dark:text-white">
									Due Date
								</dt>
								<dd class="mt-1 text-sm text-gray-900 dark:text-gray-400">
									{{ formatDate(view.state.assignment.data.due) }}
								</dd>
							</div>
							<div class="sm:col-span-1">
								<dt class="text-sm font-medium text-gray-500 dark:text-white">
									Total Marks
								</dt>
								<dd class="mt-1 text-sm text-gray-900 dark:text-gray-400">
									{{ view.state.assignment.data.marks }} Marks Available
								</dd>
							</div>
							<div class="sm:col-span-2">
								<dt class="text-sm font-medium text-gray-500 dark:text-white">
									Description
								</dt>
								<dd class="mt-1 text-sm text-gray-900 whitespace-pre-wrap dark:text-gray-400">
									{{ view.state.assignment.data.description }}
								</dd>
							</div>
							<div
								v-if="view.state.submission?.data.submitted"
								class="sm:col-span-2"
							>
								<dt class="text-sm font-medium text-gray-500">
									Your comments
								</dt>
								<dd class="mt-1 text-sm text-gray-900 whitespace-pre-wrap">
									{{ view.state.submission?.data.studentComments }}
								</dd>
							</div>
						</dl>
					</div>
				</div>

				<div
					v-if="view.state.selected === 'student-work'"
					class="bg-white border dark:bg-gray-800 dark:border-gray-800"
				>
					<div class="px-4 py-5 sm:px-6">
						<h2
							id="applicant-information-title"
							class="text-lg font-medium leading-6 text-gray-900 dark:text-white"
						>
							Student Work
						</h2>
						<p class="max-w-2xl mt-1 text-sm text-gray-500 dark:text-gray-400">
							{{ view.state.submittedAssignments.length }} Submission(s)
						</p>
					</div>
					<ul class="px-4 py-5 border-t border-gray-200 sm:px-6 dark:border-gray-900">
						<EbListItem
							v-for="(submission, index) in view.state.submittedAssignments"
							:key="submission"
							class="cursor-pointer"
							:left-title="view.findStudent(submission?.data.IDs.uid).name"
							:image="view.findStudent(submission?.data.IDs.uid).image"
							:left-subtitle="submission?.data.submitted ? 'Submitted' : 'Not Submitted'"
							:left-icon="submission?.data.submitted ? 'SCheckCircle' : 'SXCircle'"
							:left-icon-color="submission?.data.submitted ? 'text-green-500' : 'text-red-500'"
							:right-title="submission?.data.markedByTeacher ? `${submission?.data.marks} Marks / Grade ${submission?.data.grade}` : ''"
							:right-subtitle="submission?.data.markedByTeacher ? 'Marked' : 'Not Marked'"
							:right-icon="submission?.data.markedByTeacher ? 'SCheckCircle' : 'SXCircle'"
							:right-icon-color="submission?.data.markedByTeacher ? 'text-green-500' : 'text-red-500'"
							@click="view.openSubmissionModal(index);"
						/>
						<EbListItem
							v-for="student in view.getStudentsWithNoSubmission()"
							:key="student"
							class="opacity-50"
							:left-title="view.findStudent(student).name"
							left-subtitle="Not Submitted"
							left-icon="SXCircle"
							left-icon-color="text-red-500"
							right-subtitle="Not Marked"
							right-icon="SXCircle"
							right-icon-color="text-red-500"
							:image="view.findStudent(student).image"
						/>
					</ul>
				</div>
			</div>

			<div
				v-if="!view.state.submission?.data.submitted"
				class="sticky top-0 table w-full max-w-xs p-6 ml-4 space-y-4 bg-white border dark:bg-gray-800 dark:border-gray-800"
			>
				<h3 class="pb-2 font-medium text-gray-900 border-b border-gray-200 dark:text-white dark:border-gray-900">
					Actions
				</h3>

				<EbButton
					v-if="!view.state.submission && !view.isUserAdmin()"
					text="Open Starter Code"
					color="pink"
					class="w-full"
					@click="view.openAssignmentCode()"
				/>

				<EbButton
					v-if="view.isUserAdmin()"
					text="Edit Starter Code"
					color="pink"
					class="w-full"
					@click="view.openExistingProject(JSON.parse(view.state.assignment?.data.project))"
				/>

				<EbButton
					v-if="view.state.submission"
					text="Open Your Code"
					color="pink"
					class="w-full"
					@click="view.openAssignmentCode()"
				/>

				<EbButton
					v-if="view.state.submission && !view.state.submission?.data.submitted"
					text="Submit Assignment"
					color="gray"
					class="w-full"
					@click="view.submitAssignment()"
				/>

				<EbButton
					v-if="view.isUserAdmin()"
					text="Delete Assignment"
					color="gray"
					class="w-full"
				/>

				<EbInput
					v-if="!view.state.submission?.data.submitted && !view.isUserAdmin()"
					v-model="view.state.studentComments"
					label="Your Comments"
					textarea
					background="white"
				/>
			</div>
		</div>
		<EbModal
			:visible="view.state.isSubmissionModalActive"
			title="Submission Details"
		>
			<template #content>
				<div class="space-y-6">
					<EbButton
						text="Open Student Code"
						color="pink"
						class="w-full"
						@click="view.openSubmissionCode(view.state.submittedAssignments[view.state.activeSubmission].data.xmlCode, JSON.parse(view.state.assignment?.data.project))"
					/>
					<EbInput
						v-model="view.state.submittedAssignments[view.state.activeSubmission].data.studentComments"
						label="Student Comments"
						textarea
						read-only
						background="white"
					/>
					<EbInput
						v-model="view.state.submittedAssignments[view.state.activeSubmission].data.marks"
						label="Marks"
						background="white"
						type="number"
					/>
					<EbInput
						v-model="view.state.submittedAssignments[view.state.activeSubmission].data.grade"
						label="Grade"
						background="white"
					/>
					<EbInput
						v-model="view.state.submittedAssignments[view.state.activeSubmission].data.teacherComments"
						label="Teacher Comments"
						textarea
						background="white"
					/>
					<EbToggle
						title="Show as marked"
						subtitle="Students will be able to see your comments and grade."
						:toggle="view.state.submittedAssignments[view.state.activeSubmission].data.markedByTeacher"
						@toggled="view.state.submittedAssignments[view.state.activeSubmission].data.markedByTeacher = true"
						@untoggled="view.state.submittedAssignments[view.state.activeSubmission].data.markedByTeacher = false"
					/>
				</div>
			</template>
			<template #buttons>
				<EbButton
					:text="view.getText('close')"
					color="gray"
					@click="view.state.isSubmissionModalActive = false"
				/>
				<EbButton
					:text="view.getText('save')"
					color="pink"
					@click="view.editSubmission(view.state.submittedAssignments[view.state.activeSubmission]?.id)"
				/>
			</template>
		</EbModal>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { ClassroomAssignmentModel } from "./classroom-assignment-model";
import { formatDate } from "@/data/providers/global-provider";

export default defineComponent({
	name: View.ClassroomAssignment,
	setup() {
		const view: ClassroomAssignmentModel = new ClassroomAssignmentModel();

		onMounted(() => {
			view.init();
		});

		return { view, formatDate };
	}
});
</script>
