
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { NavigationItem } from "@/data/providers/navigation-provider";
import { EbNav } from "./eb-nav";

export default defineComponent({
	name: ComponentName.EbNav,
	props: {
		options: {
			type: Object as () => NavigationItem
		}
	},
	setup() {
		const component: EbNav = new EbNav();

		return { component };
	}
});
