/* eslint-disable brace-style */
/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { reactive } from "vue";
import { blocklyDiv, EditorState, code, xmlCode } from "./editor-state";
import { state } from "@/data/providers/global-provider";
import { getToolboxXml } from "@/platforms/blocks";
import { html } from "@/platforms/html";
import { HTMLGenerator } from "@/platforms/html/html-generator";
import { EditorButton, EditorButtons, EditorTab, EditorView } from "./editor-type";
import { getTranslatedText } from "@/data/providers/localization-provider";
import pretty from "pretty";
import { saveFileToFirebaseAsync } from "@/data/providers/files-provider";
import { authentication } from "@/data/providers/authentication-provider";
import router from "@/router";
import * as firebase from "firebase/app";

export class EditorModel {
	/**
	 * Get Translated text for the editor view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("editor", key);
	}
		
	// Editor view state
	public state: EditorState = reactive(new EditorState);

	/**
	 * Set global platform based on selection
	 */
	public setGlobalPlatform(): void {
		state.platform = this.state.selected;
		this.switchView(EditorView.Split);
	}

	/**
	 * Resize Blockly Window
	 */
	public resizeWindow(): void {
		window.dispatchEvent(new Event("resize"));
	}

	/**
	 * Run Python Code
	 */
	public runPythonCode(): void {
		this.state.isTrinketWindowActive = true;
		this.state.isCodeWindowActive = false;
	}

	/**
	 * Run Python Code
	 */
	public stopPythonCode(): void {
		this.state.isTrinketWindowActive = false;
		this.state.isCodeWindowActive = true;
	}

	/**
	 * Clear editor
	 */
	public clear(): void {
		this.state.isTrinketWindowActive = false;
		this.state.isCodeWindowActive = true;
		this.state.view = EditorView.Split;
		xmlCode.value = "";
		code.value = "";
		state.platform = undefined;
		state.filename = "";
	}

	/**
	 * Switch Editor View
	 */
	public async switchView(view: EditorView): Promise<void> {
		this.state.view = view;
		switch (view) {
			case EditorView.Split:
				if (state.platform?.name === html.name) {
					this.state.isCodeWindowActive = false;
					this.state.isBlockEditorActive = true;
					this.state.isHTMLPreviewActive = true;
				}
				else {
					this.state.isCodeWindowActive = true;
					this.state.isBlockEditorActive = true;
				}
				break;
			case EditorView.Blocks:
				this.state.isCodeWindowActive = false;
				this.state.isBlockEditorActive = true;
				this.state.isHTMLPreviewActive = false;
				break;
			case EditorView.Code:
				this.state.isCodeWindowActive = true;
				this.state.isBlockEditorActive = false;
				this.state.isHTMLPreviewActive = false;
				break;
		}
		await window.dispatchEvent(new Event("resize"));
		await this.resizeWindow();
	}

	/**
	 * Define list of tabs
	 */
	public editorTabs: Array<EditorTab> = [
		{ title: this.getText("split"), icon: "TemplateIcon", active: EditorView.Split, action: (): void => {
			this.switchView(EditorView.Split); 
		}},
		{ title: this.getText("blocks"), icon: "PuzzleIcon", active: EditorView.Blocks, action: (): void => {
			this.switchView(EditorView.Blocks); 
		}},
		{ title: this.getText("code"), icon: "CodeIcon", active: EditorView.Code, action: (): void => {
			this.switchView(EditorView.Code); 
		}}
	]

	/**
	 * Get data for an Editor Button
	 */
	public getEditorButton(button: EditorButtons): EditorButton {
		let editorButton: EditorButton = { text: "", color: "", visible: true, action: (): void => { null; } };
		switch (button) {
			case EditorButtons.DownloadHex:
				editorButton = { 
					text: this.getText("download-hex"), 
					color: "green", 
					icon: "LightningBoltIcon", 
					action: (): void => { this.downloadHex(); }
				};
				break;
			case EditorButtons.Save:
				editorButton = { 
					text: this.state.isSaved ? "Saved!" : this.getText("save"), 
					color: this.state.isSaved ? "green" : "gray", 
					icon: this.state.isSaved ? "CheckIcon" : "SaveIcon", 
					action: (): void => { saveFileToFirebaseAsync().then(() => {this.state.isSaved = true; setTimeout(() => {this.state.isSaved = false;}, 2000);}); }
				};
				break;
			case EditorButtons.Run:
				editorButton = { 
					text: this.state.isTrinketWindowActive ? this.getText("stop") : this.getText("run"), 
					color: this.state.isTrinketWindowActive ? "red" : "green", 
					icon: this.state.isTrinketWindowActive ? "StopIcon" : "FlagIcon", 
					action: (): void => { this.state.isTrinketWindowActive ? this.stopPythonCode() : this.runPythonCode(); }
				};
				break;
			case EditorButtons.Popout:
				editorButton = { 
					text: this.getText("popout-preview"), 
					color: "gray", 
					icon: "ExternalLinkIcon", 
					action: (): void => { 
						const win: Window | null = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=500");
						if (win) {
							win.document.body.innerHTML = code.value;
						}
					}
				};
				break;
		}
		return editorButton;
	}

	/**
	 * Set Blockly XML Code
	 */
	public setXml(xml?: string | null): void {
		if (!Blockly.mainWorkspace) {
		  throw new Error("No Workspace");
		}
	
		Blockly.mainWorkspace.clear();
	
		let start: number | undefined = 0;
		let newXml: string ="<xml xmlns=\"https://developers.google.com/blockly/xml\"><block type=\"events_start_here\" id=\"DI_start_here\" x=\"" + 25 +"\" y=\"33\" deletable=\"false\" movable=\"false\"></block></xml>";
		if (state.platform?.name !== html.name) {
			if (typeof xml === "string") {
		  	start = xml.search("DI_start_here");
				if (start < 0) {
					const firstBlockPosition: number = xml.search("<block");
					const startBlockXml: string = "<block type=\"events_start_here\" id=\"DI_start_here\" x=\"" +25 +"\" y=\"33\" deletable=\"false\" movable=\"false\">";
	
					if (firstBlockPosition < 0) {
						// No Blocks
					}
					else {
						const posFromEndOfString: number = -1 * "</xml>".length;
						newXml = xml.slice(0, firstBlockPosition) + startBlockXml + "<next>" + xml.slice(firstBlockPosition, posFromEndOfString) + "</next></block>" + xml.slice(posFromEndOfString);
					}
					const textToDom: Element = Blockly.Xml.textToDom(newXml);
					Blockly.Xml.domToWorkspace(textToDom, Blockly.mainWorkspace);
				}
				else {
					const textToDom: Element = Blockly.Xml.textToDom(xml);
					Blockly.Xml.domToWorkspace(textToDom, Blockly.mainWorkspace);
				}
			}
		}
		else {
			if (typeof xml === "string" && xml) {
				const textToDom: Element = Blockly.Xml.textToDom(xml);
				Blockly.Xml.domToWorkspace(textToDom, Blockly.mainWorkspace);
			}
		}
	}
	
	/**
	 * Loading blockly editor
	 */
	public async loadBlockly(): Promise<void> {
		// window.onbeforeunload = function(): boolean {
		// 	return true;
		// };
		const toolbox: string = await getToolboxXml();
	
		if (Blockly.mainWorkspace) {
		  Blockly.mainWorkspace.dispose();
		}
	
		const options: object = {
		  media: "blockly/media/",
		  renderer: "pxt",
		  trashcan: false,
		  zoom: {
				controls: true,
				wheel: true,
				startScale: 1.0,
				maxScale: 3,
				minScale: 0.3,
				scaleSpeed: 1.2
		  },
		  pinch: true,
		  grid: {
				spacing: 25,
				length: 3,
				colour: "#ccc",
				snap: true
		  },
		  toolbox
		};
	
		const blocklyWorkspace: Blockly.WorkspaceSvg = Blockly.inject(
		  blocklyDiv.value,
		  options
		);

		if (state.platform?.name !== html.name) {
			blocklyWorkspace.addChangeListener(Blockly.Events.disableOrphans);
		}
				
		blocklyWorkspace.addChangeListener(() => {
			xmlCode.value = Blockly.Xml.domToPrettyText(
				Blockly.Xml.workspaceToDom(blocklyWorkspace)
		  );
		  if (!blocklyWorkspace.isDragging()) {
			  	this.state.isSaved = false;
				if (state.platform?.name === html.name) {
					code.value = pretty(HTMLGenerator.workspaceToCode(blocklyWorkspace));
				}
				else {
					code.value = Blockly.Python.workspaceToCode(blocklyWorkspace);
				}
		  }
		});
	
		this.setXml(xmlCode.value);
	  }

	  public async saveToAssignment(): Promise<void> {
		await authentication.db.collection("classrooms").doc(router.currentRoute.value.query.classroomID?.toString()).collection("assignments").doc(router.currentRoute.value.query.assignmentID?.toString()).collection("submissions").where("IDs", "==", {assignmentID: router.currentRoute.value.query.assignmentID?.toString(), uid: authentication.currentUser.value?.uid}).get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
			if (snapshot.docs.length > 0) {
				snapshot.forEach((doc: firebase.default.firestore.QueryDocumentSnapshot) => {
					if (doc.data()) {
						doc.ref.update({
							xmlCode: xmlCode.value
						});
					}
				});
			}
			else {
				this.createAssignmentSubmission();
			}
		});
	}

	public async createAssignmentSubmission(): Promise<void> {
		await authentication.db.collection("classrooms").doc(router.currentRoute.value.query.classroomID?.toString()).collection("assignments").doc(router.currentRoute.value.query.assignmentID?.toString()).collection("submissions").add({
			IDs: { uid: authentication.currentUser.value?.uid, assignmentID: router.currentRoute.value.query.assignmentID?.toString() },
			xmlCode: xmlCode.value,
			submitted: false,
			markedByTeacher: false,
			marks: 0,
			grade: "",
			studentComments: "",
			teacherComments: "",
		}).then((doc: firebase.default.firestore.DocumentReference) => {
			authentication.db.collection("classrooms").doc(router.currentRoute.value.query.classroomID?.toString()).collection("assignments").doc(router.currentRoute.value.query.assignmentID?.toString()).update({
				submissions: firebase.default.firestore.FieldValue.arrayUnion(doc.id)
			});
		});
	}

	public downloadHex(): void {
		let filename: string = "";
		if (state.filename) {
			filename = state.filename;
		}
		else {
			filename = "untitled";
		}
		fsUniversalHex(code.value, filename);
	}
}