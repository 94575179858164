import { getTranslatedText } from "@/data/providers/localization-provider";
import { HomeState } from "./home-state";
import { reactive, toRaw } from "vue";
import { FirebaseFile, getAllProjectsAsync, getLocalDataForFile } from "@/data/providers/files-provider";
import { xmlCode } from "../editor/editor-state";
import { state } from "@/data/providers/global-provider";
import router from "@/router";

export class HomeModel {
	// State for home View
	public state: HomeState = reactive(new HomeState());
		
	/**
	 * Get Translated text for the home view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("home", key);
	}

	/**
	 * Open a project in the editor
	 */
	public openProject(project: FirebaseFile): void {
		fetch(project.downloadURL).then((response: Response) => {
			return response.blob();
		}).then((blob: Blob) => {
			blob.text().then((xml: string) => {
				xmlCode.value = xml;
				state.filename = getLocalDataForFile(project).shortTitle;
				state.platform = getLocalDataForFile(project).platform;
				router.push({path: "/editor"});
			});
		});
	}
	
	/**
	 * Get all projects from firebase and push to view state
	 */
	public getAllFilesFromFirebase(): void {
		this.state.isBusy = true;
		getAllProjectsAsync().then((files: firebase.default.storage.ListResult | undefined) => {
			if (files) {
				files.items.forEach((ref: firebase.default.storage.Reference) => {
					ref.getDownloadURL().then((url: string) => {
						ref.getMetadata().then((meta: firebase.default.storage.FullMetadata) => {
							this.state.projects.push({label: ref.name, ref: toRaw(ref), downloadURL: url, metadata: meta});
							this.state.isBusy = false;
						});
					});
				});
			}
		});
	}
}