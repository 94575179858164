/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { authentication } from "./authentication-provider";
import * as firebase from "firebase/app";
import { Platform, platforms } from "./platform-provider";
import { state } from "./global-provider";
import { xmlCode } from "@/views/editor/editor-state";

export interface FirebaseFile {
	label: string;
	ref: firebase.default.storage.Reference;
	downloadURL: string;
	metadata?: firebase.default.storage.FullMetadata;
}

export interface FileLocalData {
	platform?: Platform;
	shortTitle?: string;
} 

export async function getAllProjectsAsync(): Promise<firebase.default.storage.ListResult | undefined> {
	let files: firebase.default.storage.ListResult | undefined;
	if (authentication.currentUser.value) {
		const ref: firebase.default.storage.Reference = firebase.default.storage().ref(`blocks/${authentication.currentUser.value.uid}`);
		await ref.listAll().then((res: firebase.default.storage.ListResult) => {
			files = res;
		});
	} 
	return files;
}

export function getLocalDataForFile(file: FirebaseFile): FileLocalData {
	const data: FileLocalData = {};
	platforms.forEach((platform: Platform) => {
		if (file.label.includes(`(${platform.key})`)) {
			data.shortTitle = file.label.replace(`(${platform.key})`, "");
			data.platform = platform;
		}
	});
	return data;
}

export async function deleteFirebaseFileAsync(ref: firebase.default.storage.Reference): Promise<void> {
	await ref.delete();
}

export async function saveFileToFirebaseAsync(): Promise<void> {
	if (authentication.currentUser.value) {
		const ref: firebase.default.storage.Reference = firebase.default.storage().ref(`blocks/${authentication.currentUser.value.uid}/${state.filename?.trim()} (${state.platform?.key})`);
		await ref.putString(xmlCode.value);
	}
}