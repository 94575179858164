<template>
	<div class="space-y-8">
		<div class="relative px-6 py-10 overflow-hidden bg-navy-500 rounded-2xl sm:px-12 sm:py-16 dark:bg-gray-800 dark:border-gray-800 dark:border">
			<div
				aria-hidden="true"
				class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
			>
				<svg
					class="absolute inset-0 w-full h-full"
					preserveAspectRatio="xMidYMid slice"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 1463 360"
				>
					<path
						class="text-navy-300 text-opacity-40 dark:text-gray-700"
						fill="currentColor"
						d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
					/>
					<path
						class="text-navy-700 text-opacity-40 dark:text-gray-900"
						fill="currentColor"
						d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
					/>
				</svg>
			</div>
			<div class="relative flex items-center justify-between space-x-16">
				<img
					src="/assets/images/general/v4release.jpg"
					class="h-60 rounded-2xl"
				>
				<div>
					<h1 class="text-4xl font-extrabold text-white">
						Welcome to EduBlocks V4!
					</h1>
					<p class="max-w-2xl mx-auto mt-6 text-lg text-navy-200 dark:text-gray-400">
						It's finally here! The biggest update ever to EduBlocks which introduces a brand new HTML editor, Public Showcase, Classroom tool and so much more. Learn more in our release video or start coding by creating a project.
					</p>
					<EbButton
						text="New Project"
						icon="PlusIcon"
						color="pink"
						class="mt-6"
						size="lg"
						@click="this.$router.push({path: '/editor'})"
					/>
				</div>
			</div>
		</div>

		<!-- Saved Projects Section -->
		<div v-if="authentication.currentUser.value">
			<EbPageHeader :title="view.getText('recent-projects')">
				<EbButton
					color="pink"
					icon="PlusIcon"
					type="rounded"
					size="none"
					@click="this.$router.push({path: '/editor'})"
				/>
			</EbPageHeader>
			<div class="w-full pb-1 overflow-x-auto">
				<div class="grid w-full grid-flow-col gap-5 overflow-x-auto auto-cols-max">
					<EbThumbnailCardSkeleton
						v-for="card in 5"
						:key="card"
						:visible="view.state.isBusy"
						fixed
					/>
					<EbThumbnailCard
						v-for="project in view.state.projects.slice(0,5)"
						:key="project"
						:title="getLocalDataForFile(project).shortTitle" 
						:subtitle="getLocalDataForFile(project).platform?.name"
						:gradient-image="getLocalDataForFile(project).platform?.icon"
						thumbnail-gradient
						fixed
						@click="view.openProject(project)"
					/>
				</div>
			</div>
		</div>

		<div v-else>
			<EbPageHeader :title="view.getText('create-new-project')">
				<EbButton
					color="pink"
					icon="PlusIcon"
					type="rounded"
					size="none"
				/>
			</EbPageHeader>
			<div class="w-full pb-1 overflow-x-auto">
				<div class="grid w-full grid-flow-col gap-5 overflow-x-auto auto-cols-max">
					<EbThumbnailCard
						v-for="platform in platforms"
						:key="platform"
						:title="platform.name" 
						:subtitle="platform.description"
						:gradient-image="platform.icon"
						thumbnail-gradient
						fixed
					/>
				</div>
			</div>
		</div>

		<!-- Guides Section -->
		<div>
			<EbPageHeader :title="view.getText('guides')" />
			<div class="w-full pb-1 overflow-x-auto">
				<div class="grid w-full grid-flow-col gap-5 overflow-x-auto auto-cols-max">
					<EbThumbnailCard
						title="Create code"
						subtitle="Python 3"
						fixed
						image="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { platforms } from "@/data/providers/platform-provider";
import { View } from "@/views/constants";
import { HomeModel } from "./home-model";
import { getLocalDataForFile } from "@/data/providers/files-provider";
import { formatDate } from "@/data/providers/global-provider";
import { authentication } from "@/data/providers/authentication-provider";

export default defineComponent({
	name: View.Home,
	setup() {
		const view: HomeModel = new HomeModel();

		onMounted(() => {
			view.getAllFilesFromFirebase();
		});

		return { view, platforms, getLocalDataForFile, formatDate, authentication };
	}
});
</script>
