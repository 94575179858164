<template>
	<li>
		<a class="block group">
			<div class="flex items-center px-4 py-5 sm:py-6 sm:px-0">
				<div class="flex items-center flex-1 min-w-0">
					<div class="flex-shrink-0">
						<img
							class="w-12 h-12 rounded-full group-hover:opacity-75"
							:src="image"
						>
					</div>
					<div class="flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4">
						<div>
							<p class="text-sm font-medium text-pink-500 truncate">{{ leftTitle }}</p>
							<p class="flex items-center mt-2 text-sm text-gray-500 dark:text-gray-400">
								<component
									:is="leftIcon"
									class="flex-shrink-0 mr-1.5 h-5 w-5"
									:class="leftIconColor"
								/>
								{{ leftSubtitle }}
							</p>
						</div>
						<div class="hidden md:block">
							<div>
								<p class="text-sm text-gray-900 dark:text-white">
									{{ rightTitle }}
								</p>
								<p class="flex items-center mt-2 text-sm text-gray-500 dark:text-gray-400">
									<component
										:is="rightIcon"
										class="flex-shrink-0 mr-1.5 h-5 w-5"
										:class="rightIconColor"
									/>
									{{ rightSubtitle }}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<ChevronRightIcon class="w-5 h-5 text-gray-400 group-hover:text-gray-700" />
				</div>
			</div>
		</a>
	</li>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbListItem,
	props: {
		leftTitle: {
			type: String
		},
		leftSubtitle: {
			type: String
		},
		leftIcon: {
			type: String
		},
		leftIconColor: {
			type: String
		},
		rightTitle: {
			type: String
		},
		rightSubtitle: {
			type: String
		},
		rightIcon: {
			type: String
		},
		rightIconColor: {
			type: String
		},
		image: {
			type: String
		},
	}
});
</script>
