<template>
	<div class="flex items-center justify-between">
		<span
			id="toggleLabel"
			class="flex flex-col flex-grow mr-4"
		>
			<span
				v-if="title"
				class="text-sm text-gray-900 dark:text-white"
			>
				{{ title }}
			</span>
			<span
				v-if="subtitle"
				class="text-xs leading-normal text-gray-500 dark:text-gray-400"
			>
				{{ subtitle }}
			</span>
		</span>
		<div
			class="w-6 h-6 border rounded-lg dark:border-gray-900"
			:class="component.toggled.value ? 'bg-pink-500' : 'bg-gray-200'"
			@click="toggleSwitch()"
		/>
	</div>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from "vue";
import { ComponentName } from "../constants";
import { Data } from "../types";
import { EbToggle } from "./eb-toggle";

export default defineComponent({
	name: ComponentName.EbToggle,
	props: {
		title: {
			type: String,
			default: ""
		},
		subtitle: {
			type: String,
			default: ""
		},
		toggle: {
			type: Boolean,
			default: false
		}
	},
	emits: ["toggled", "untoggled"],
	// eslint-disable-next-line @typescript-eslint/typedef
	setup(props: Data, { emit }) {
		const component: EbToggle = new EbToggle();
		
		function toggleSwitch(): void {
			component.toggled.value = !component.toggled.value;
			if (component.toggled.value) {
				emit("toggled");
			}
			else {
				emit("untoggled");
			}
		}

		watchEffect(() => {
			if (props.toggle) {
				component.toggled.value = true;
			}
		});

		return {
			component, toggleSwitch
		};
	}
});
</script>