import { Platform } from "@/data/providers/platform-provider";
import { EditorButtons } from "@/views/editor/editor-type";

export const python: Platform = {
	name: "Python 3",
	key: "Python",
	image: "/assets/images/platforms/python.png",
	icon: "/assets/images/platforms/python-icon.svg",
	color: "navy",
	actions: [EditorButtons.Save, EditorButtons.Run]
};