import { getTranslatedText } from "@/data/providers/localization-provider";

export class EbNav {
	/**
	 * Get Translated text for the home view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("navigation", key);
	}
}