export enum ComponentName {
	EbNav = "EbNav",
	EbHeader = "EbHeader",
	EbButton = "EbButton",
	EbPageHeader = "EbPageHeader",
	EbCard = "EbCard",
	EbThumbnailCard = "EbThumbnailCard",
	EbThumbnailCardSkeleton = "EbThumbnailCardSkeleton",
	EbRadioGroup = "EbRadioGroup",
	EbToolbar = "EbToolbar",
	EbTab = "EbTab",
	EbInput = "EbInput",
	EbTrinket = "EbTrinket",
	EbUserMenu = "EbUserMenu",
	EbSelector = "EbSelector",
	EbModal = "EbModal",
	EbSelect = "EbSelect",
	EbListItem = "EbListItem",
	EbToggle = "EbToggle"
}