<template>
	<div
		class="relative border border-gray-300 rounded-md dark:border-gray-700"
	>
		<select
			v-model="selectedValue"
			class="z-10 block w-full px-4 py-3 text-gray-900 bg-transparent border-transparent rounded-md appearance-none text-md focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-pink-500 dark:text-white"
			@change="$emit('selected', selectedValue)"
		>
			<option
				v-for="option in options"
				:key="option"
				:value="option.value"
			>
				{{ option.title }}
			</option>
		</select>
		<label
			v-if="label"
			class="absolute top-0 z-0 px-1 py-0 ml-3 text-gray-400 duration-200 transform scale-90 -translate-y-3 bg-white pointer-events-none text-md origin-0 dark:bg-gray-800"
		>
			{{ label }}
		</label>
	</div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbSelect,
	props: {
		modelValue: {
			type: Object,
		},
		options: {
			type: Array,
		},
		label: {
			type: String,
		}
	},
	emits: ["selected"],
	setup() {
		const selectedValue: Ref = ref();

		return {selectedValue};
	}
	
});
</script>

<style lang="postcss" scoped>
select:focus-within ~ label {
  @apply text-pink-500 bg-gray-50 dark:bg-gray-800;
}
</style>