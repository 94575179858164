import clb from "clb";

export class EbCard {
	public variants: Function = clb({
		defaults: {
			color: "navy"
		},
		variants: {
			color: {
				pink: "bg-pink-500",
				navy: "bg-navy-500",
				blue: "bg-blue-500",
				green: "bg-green-400",
				purple: "bg-purple-500",
				plum: "bg-pink-600",
			}
		}
	})
}