<template>
	<div class="flex flex-col overflow-hidden transition-all bg-white border-2 rounded-lg cursor-pointer w-60 hover:border-pink-500">
		<div class="flex-grow px-3 py-5">
			<div class="flex items-center">
				<div
					class="flex items-center justify-center flex-shrink-0 w-12 h-12 p-2.5 rounded-md"
					:class="component.variants({color})"
				>
					<img :src="image">
				</div>
				<div class="flex-1 w-0 ml-5">
					<dd class="flex items-baseline">
						<div class="text-lg font-semibold text-gray-900 truncate">
							{{ title }}
						</div>
					</dd>
					<dt class="text-sm font-medium text-gray-500 truncate">
						{{ subtitle }}
					</dt>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { EbCard } from "./eb-card";

export default defineComponent({
	name: ComponentName.EbCard,
	props: {
		title: {
			type: String
		},
		subtitle: {
			type: String
		},
		color: {
			type: String
		},
		image: {
			type: String
		}
	},
	setup() {
		const component: EbCard = new EbCard();

		return { component };
	}
});
</script>
