
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { EbButton } from "./eb-button";

export default defineComponent({
	name: ComponentName.EbButton,
	props: {
		text: {
			type: String
		},
		type: {
			type: String
		},
		color: {
			type: String
		},
		size: {
			type: String
		},
		icon: {
			type: String
		},
		visible: {
			type: Boolean,
			default: true
		}
	},
	setup() {
		const component: EbButton = new EbButton();

		return { component };
	}
});
