<template>
	<div
		v-if="view.state.classroom"
		class="flex flex-col w-full h-full"
	>
		<div class="flex w-full h-full space-x-6">
			<div class="flex-1 w-full h-full">
				<EbPageHeader
					:title="view.state.classroom?.data.name"
					no-border
					back-arrow
					@arrowClick="this.$router.push({path: '/classroom'})"
				>
					<EbButton
						v-if="view.state.selected === 'active-assignments' && view.isUserAdmin() || view.state.selected === 'past-assignments' && view.isUserAdmin()"
						:text="view.getText('new-assignment')"
						icon="PlusIcon"
						color="pink"
						@click="view.state.isCreateAssignmentModalOpen = true"
					/>
					<EbButton
						v-if="view.state.selected === 'live-lessons' && view.isUserAdmin()"
						:text="view.getText('new-live-lesson')"
						icon="PlusIcon"
						color="pink"
					/>
				</EbPageHeader>
				<EbSelector
					:items="view.getTabs()"
					selected="active-assignments"
				/>
				<div
					v-if="view.state.selected === 'active-assignments'"
					class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
				>
					<EbThumbnailCard
						v-for="assignment in view.state.activeAssignments"
						:key="assignment"
						:title="assignment.data.title"
						:visible="view.isAssignmentActive(assignment.data.due)"
						:subtitle="`Due - ${new Date(assignment.data.due).toDateString()}`"
						thumbnail-gradient
						:gradient-image="`/assets/images/calendar/${new Date(assignment.data.due).getDate()}.svg`"
						@click="this.$router.push({path: `/classroom/${this.$route.params.id}/assignment/${assignment.id}`})"
					/>
				</div>
				<div
					v-if="view.state.selected === 'past-assignments'"
					class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
				>
					<EbThumbnailCard
						v-for="assignment in view.state.pastAssignments"
						:key="assignment"
						:title="assignment.data.title"
						:visible="!view.isAssignmentActive(assignment.data.due)"
						:subtitle="`Due - ${new Date(assignment.data.due).toDateString()}`"
						thumbnail-gradient
						:gradient-image="`/assets/images/calendar/${new Date(assignment.data.due).getDate()}.svg`"
						@click="this.$router.push({path: `/classroom/${this.$route.params.id}/assignment/${assignment.id}`})"
					/>
				</div>
			</div>
			<div class="w-full h-full max-w-xs p-6 ml-4 space-y-6 bg-white border dark:bg-gray-800 dark:border-gray-800">
				<div>
					<h3 class="font-medium text-gray-900 dark:text-white">
						{{ view.getText('students') }}
					</h3>
					
					<ul class="mt-2 border-t border-gray-200 dark:border-gray-900">
						<li
							v-for="student in view.state.students"
							:key="student"
							class="flex items-center justify-between py-3"
						>
							<div class="flex items-center">
								<img
									:src="student.image"
									class="w-8 h-8 rounded-full"
								>
								<p class="ml-4 text-sm font-medium text-gray-900 dark:text-white">
									{{ student.name }}
								</p>
							</div>
							<TrashIcon
								v-if="view.isUserAdmin()"
								class="w-4 h-4 ml-3 text-red-500"
							/>
						</li>
						<li
							v-if="view.isUserAdmin()"
							class="flex items-center justify-between py-2"
						>
							<div
								class="flex items-center p-1 -ml-1 cursor-pointer group"
								@click="view.getClassroomJoinCode()"
							>
								<div class="relative w-8 h-8 transition-all">
									<span class="flex items-center justify-center w-8 h-8 text-gray-400 border-2 border-gray-300 border-dashed rounded-full group-hover:animate-spin-slow" />
									<PlusIcon class="absolute top-1.5 w-5 h-5 left-1.5 text-gray-500 group-hover:text-pink-500 dark:text-gray-400" />
								</div>
								<span class="ml-4 text-sm font-medium text-pink-500 group-hover:text-pink-400">{{ view.getText('add-students') }}</span>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<EbModal
		:title="view.getText('new-assignment')"
		:visible="view.state.isCreateAssignmentModalOpen"
	>
		<template #content>
			<div class="space-y-4">
				<EbInput
					v-model="view.newAssignmentFormData.title"
					label="Name"
					background="white"
				/>
				<EbInput
					v-model="view.newAssignmentFormData.description"
					label="Description"
					background="white"
					textarea
				/>
				<EbInput
					v-model="view.newAssignmentFormData.due"
					label="Due Date"
					background="white"
					type="datetime-local"
				/>
				<EbInput
					v-model="view.newAssignmentFormData.marks"
					label="Total Marks"
					background="white"
					type="number"
				/>
				<EbSelect
					label="Starter Project"
					:options="view.state.files"
					@selected="view.newAssignmentFormData.project = $event"
				/>
			</div>
		</template>
		<template #buttons>
			<EbButton
				:text="view.getText('close')"
				color="gray"
				@click="view.state.isCreateAssignmentModalOpen = false"
			/>
			<EbButton
				:text="view.getText('create')"
				color="pink"
				@click="view.createNewAssignment()"
			/>
		</template>
	</EbModal>

	<EbModal
		title="Add Students"
		:visible="view.state.isAddStudentsModalOpen"
	>
		<template #content>
			<h1 class="w-full font-semibold text-center text-9xl">
				{{ view.state.joinCode }}
			</h1>
		</template>
		<template #buttons>
			<EbButton
				:text="view.getText('close')"
				color="gray"
				@click="view.state.isAddStudentsModalOpen = false"
			/>
		</template>
	</EbModal>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { ClassroomModel } from "./classroom-detail-model";

export default defineComponent({
	name: View.ClassroomDetail,
	setup() {
		const view: ClassroomModel = new ClassroomModel();

		onMounted(() => {
			view.init();
		});

		return { view };
	}
});
</script>
