<template>
	<div
		v-if="authentication.currentUser.value"
		v-click-outside="component.closeDropdown"
		class="relative z-50 ml-4"
	>
		<img
			v-if="authentication.currentUser.value.photoURL"
			:src="authentication.currentUser.value.photoURL"
			class="w-8 h-8 rounded-full cursor-pointer"
			@click="isDropdownOpen = !isDropdownOpen"
		>
		<span
			v-else
			class="inline-block w-8 h-8 overflow-hidden bg-gray-100 border rounded-full cursor-pointer"
			@click="isDropdownOpen = !isDropdownOpen"
		>
			<svg
				class="w-full h-full text-gray-300"
				fill="currentColor"
				viewBox="0 0 24 24"
			>
				<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
			</svg>
		</span>
		<transition
			enter-active-class="transition duration-200 ease-out"
			enter-from-class="transform scale-95 opacity-0"
			enter-to-class="transform scale-100 opacity-100"
			leave-active-class="transition duration-75 ease-in"
			leave-from-class="transform scale-100 opacity-100"
			leave-to-class="transform scale-95 opacity-0"
		>
			<div
				v-show="isDropdownOpen"
				class="absolute right-0 z-50 w-48 py-1 mt-2 origin-top-right bg-white border rounded-md shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-gray-800 dark:border-gray-700 dark:divide-gray-700 dark:backdrop-filter dark:backdrop-blur dark:bg-opacity-80"
			>
				<div class="px-4 py-3">
					<p class="text-sm dark:text-white">
						Signed in as
					</p>
					<p class="text-sm font-medium text-gray-900 truncate dark:text-gray-400">
						{{ authentication.currentUser.value?.email }}
					</p>
				</div>
				<div class="py-1 border-t">
					<button
						class="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 dark:text-gray-300 dark:hover:bg-white dark:hover:bg-opacity-20 dark:hover:text-white"
						@click="component.signOut()"
					>
						Sign out
					</button>
				</div>
			</div>
		</transition>
	</div>
	<EbButton
		v-else
		type="rounded"
		icon="LoginIcon"
		size="none"
		color="pink"
		class="ml-4"
		@click="this.$router.push({path: '/login'})"
	/>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { EbUserMenu, isDropdownOpen } from "./eb-user-menu";
import { state } from "@/data/providers/global-provider";
import { authentication } from "@/data/providers/authentication-provider";

export default defineComponent({
	name: ComponentName.EbUserMenu,
	setup() {
		const component: EbUserMenu = new EbUserMenu();

		return { component, state, isDropdownOpen, authentication };
	}
});
</script>
