<template>
	<transition
		enter-active-class="transition duration-300 ease-out"
		enter-from-class="opacity-0"
		enter-to-class="opacity-100"
		leave-active-class="transition duration-150 ease-in"
		leave-from-class="opacity-100"
		leave-to-class="opacity-0"
	>
		<div
			v-if="visible"
			class="absolute inset-0 z-40 w-screen h-screen bg-gray-300 opacity-70 dark:bg-gray-600"
		/>
	</transition>
	
	<transition
		enter-active-class="transition duration-300 ease-out"
		enter-from-class="transform scale-95 opacity-0"
		enter-to-class="transform scale-100 opacity-100"
		leave-active-class="transition duration-150 ease-in"
		leave-from-class="transform scale-100 opacity-100"
		leave-to-class="transform scale-95 opacity-0"
	>
		<div
			v-if="visible"
			class="absolute inset-0 z-50 flex items-center justify-center w-screen h-screen"
		>
			<div class="inline-block overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:max-w-lg sm:w-full dark:bg-gray-900">
				<div class="px-4 pt-5 pb-4 space-y-4 bg-white sm:p-6 dark:bg-gray-800">
					<h1
						v-if="title"
						class="mb-6 text-lg font-medium leading-6 text-gray-900 dark:text-white"
					>
						{{ title }}
					</h1>
					<slot name="content" />
				</div>
				<div class="flex px-6 py-3 bg-gray-50 dark:bg-gray-900">
					<div class="flex ml-auto space-x-2">
						<slot name="buttons" />
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
 
export default defineComponent({
	name: ComponentName.EbModal,
	props: {
		title: {
			type: String
		},
		visible: {
			type: Boolean
		}
	}
});
</script>
