
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { ProjectsModel } from "./projects-model";
import { getLocalDataForFile } from "@/data/providers/files-provider";

export default defineComponent({
	name: View.Projects,
	setup() {
		const view: ProjectsModel = new ProjectsModel();

		onMounted(() => {
			view.init();
		});

		return { view, getLocalDataForFile };
	}
});
