<template>
	<div class="flex h-16 px-6 bg-white border-b border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-800">
		<div class="flex-grow h-full" />
		<div
			v-if="!state.assignmentModeActive && !state.assignmentViewModeActive"
			class="flex items-center justify-center flex-grow h-full"
		>
			<h1 class="text-gray-400">
				{{ state.platform?.name }}
			</h1>
			<span class="mx-3 dark:text-white">/</span>
			<h1
				class="px-2 py-1 text-gray-900 transition-all border border-transparent rounded-md hover:border-gray-500 focus:border-gray-500 focus:outline-none focus:ring-0 dark:text-white"
				contenteditable="true"
				data-max-length="10"
			>
				{{ state.filename }}
			</h1>
		</div>
		<div
			v-if="state.assignmentModeActive"
			class="flex items-center justify-center flex-grow h-full"
		>
			<h1 class="text-gray-900">
				You're editing an assignment task!
			</h1>
		</div>
		<div
			v-if="state.assignmentViewModeActive"
			class="flex items-center justify-center flex-grow h-full"
		>
			<h1 class="text-gray-900">
				You're viewing an assignment submission!
			</h1>
		</div>
		<div class="flex items-center justify-end flex-grow h-full">
			<EbUserMenu />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { EbToolbar } from "./eb-toolbar";
import { state } from "@/data/providers/global-provider";

export default defineComponent({
	name: ComponentName.EbToolbar,
	setup() {
		const component: EbToolbar = new EbToolbar();

		return { component, state };
	}
});
</script>
