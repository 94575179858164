<template>
	<div class="grid grid-cols-5 gap-6 -space-y-px rounded-md">
		<div
			v-for="option in options"
			:key="option"
		>
			<div class="relative bg-white border rounded dark:bg-gray-800 dark:border-gray-800">
				<label
					:for="option.name"
					class="flex flex-col items-center px-12 py-8 cursor-pointer"
				>
					<div class="mt-4">
						<div
							class="w-24 h-24 bg-center bg-no-repeat bg-contain"
							:style="`background-image: url(${option.image})`"
						/>
						<span class="block mt-4 font-medium text-center text-gray-800 dark:text-white">
							{{ option.name }}
						</span>
					</div>
				</label>
				<div class="absolute top-4 right-4">
					<input
						:id="option.name"
						type="radio"
						:name="name"
						:value="option"
						class="w-5 h-5 text-pink-500 border-gray-300 cursor-pointer focus:ring-pink-500 dark:border-gray-700 dark:bg-gray-900"
						@change="$emit('update:modelValue', option)"
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbRadioGroup,
	props: {
		options: {
			type: Array
		},
		name: {
			type: String
		},
		modelValue: {
			type: Object
		}
	},
	emits: ["update:modelValue"]
});
</script>
