
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbInput,
	props: {
		modelValue: {
			type: String,
		},
		label: {
			type: String,
		},
		type: {
			type: String,
		},
		background: {
			type: String,
			default: "gray"
		},
		textarea: {
			type: Boolean
		},
		readOnly: {
			type: Boolean
		}
	},
	emits: ["update:modelValue"]
});
