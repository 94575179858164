import { Platform } from "@/data/providers/platform-provider";
import { ref, Ref } from "vue";
import { EditorView } from "./editor-type";

export const blocklyDiv: Ref = ref();
export const xmlCode: Ref<string> = ref("");
export const code: Ref<string> = ref("");

export class EditorState {
	// Store local selected platform
	public selected: Platform | undefined;

	// Store editor view
	public view: EditorView | undefined;

	// Store visible state for code window
	public isCodeWindowActive: boolean = true;

	// Store visible state for trinket window
	public isTrinketWindowActive: boolean = false;

	// Store visible state for HTML Preview
	public isHTMLPreviewActive: boolean = false;

	// Store visible state for Blockly
	public isBlockEditorActive: boolean = false;

	// Store save state for file
	public isSaved: boolean = false;
}