<template>
	<EbPageHeader :title="view.getText('classroom')">
		<div class="flex space-x-4">
			<EbButton
				color="gray"
				icon="UserAddIcon"
				text="Join"
				@click="view.state.isJoinModalOpen = true"
			/>
			<EbButton
				color="pink"
				icon="PlusIcon"
				type="rounded"
				size="none"
				@click="view.state.isNewClassroomModalOpen = true"
			/>
		</div>
	</EbPageHeader>
	<div class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8">
		<EbThumbnailCardSkeleton
			v-for="card in 3"
			:key="card"
			:visible="view.state.isBusy"
		/>
		<EbThumbnailCard
			v-for="classroom in view.state.classrooms"
			:key="classroom"
			:title="classroom.data.name" 
			:subtitle="`${classroom.data.assignments.length} ${classroom.data.assignments.length === 1 ? view.getText('assignment') : view.getText('assignments')}`"
			gradient-image="/assets/images/general/classroom-icon.svg"
			thumbnail-gradient
			@click="this.$router.push({path: `/classroom/${classroom.id}`})"
		/>
	</div>
	
	<EbModal
		:title="view.getText('create-new-classroom')"
		:visible="view.state.isNewClassroomModalOpen"
	>
		<template #content>
			<div class="space-y-4">
				<EbInput
					v-model="view.newClassroomFormData.name"
					label="Name"
					background="white"
				/>
			</div>
		</template>
		<template #buttons>
			<EbButton
				:text="view.getText('close')"
				color="gray"
				@click="view.state.isNewClassroomModalOpen = false"
			/>
			<EbButton
				:text="view.getText('create')"
				color="pink"
				@click="view.createNewClass()"
			/>
		</template>
	</EbModal>

	<EbModal
		:title="view.getText('join-classroom')"
		:visible="view.state.isJoinModalOpen"
	>
		<template #content>
			<div class="space-y-6">
				<h1 class="-mt-4 text-sm text-gray-500 dark:text-gray-400">
					Ask your teacher for a code to join your class
				</h1>
				<EbInput
					v-model="view.joinClassroomFormData.code"
					label="Code"
					background="white"
				/>
			</div>
		</template>
		<template #buttons>
			<EbButton
				:text="view.getText('close')"
				color="gray"
				@click="view.state.isJoinModalOpen = false"
			/>
			<EbButton
				:text="view.getText('join')"
				color="pink"
				@click="view.goToJoinLink()"
			/>
		</template>
	</EbModal>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { ClassroomModel } from "./classroom-model";

export default defineComponent({
	name: View.Classroom,
	setup() {
		const view: ClassroomModel = new ClassroomModel();

		onMounted(() => {
			view.init();
		});

		return { view };
	}
});
</script>
