import { authentication } from "@/data/providers/authentication-provider";
import { ref, Ref } from "vue";

export const isDropdownOpen: Ref<boolean> = ref(false);

export class EbUserMenu {
	public signOut(): void {
		isDropdownOpen.value = false;
		authentication.signOut();
	}

	public closeDropdown(): void {
		isDropdownOpen.value = false;
	}
} 