import clb from "clb";

export class EbToolbar {
	public variants: Function = clb({
		defaults: {
			type: "basic"
		},
		variants: {
			type: {
				basic: "inline-flex items-center flex-none px-4 py-2 text-sm font-medium transition-all border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 space-x-2",
				rounded: "inline-flex items-center p-2 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all"
			},
			color: {
				pink: "bg-pink-500 hover:bg-pink-600 focus:ring-pink-500 text-white border-transparent",
				gray: "border-gray-300 focus:ring-gray-300 bg-white hover:bg-gray-100"
			}
		}
	})
}