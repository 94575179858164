<template>
	<div
		v-if="visible"
		class="relative m-1 animate-pulse"
	>
		<div
			class="block overflow-hidden transition-all rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500 group"
			:class="fixed ? 'aspect-w-10 aspect-h-6 w-60' : 'aspect-w-10 aspect-h-6 w-full'"
		>
			<div
				class="flex items-center justify-center w-full h-full transition-all bg-gray-200 pointer-events-none dark:bg-gray-800"
			/>
		</div>
		<div class="flex items-center justify-between">
			<div>
				<p class="block w-40 h-4 mt-2 font-bold bg-gray-300 dark:bg-gray-800" />
				<p class="block w-32 h-4 mt-1 text-sm font-medium bg-gray-200 dark:bg-gray-700" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbThumbnailCardSkeleton,
	props: {
		fixed: {
			type: Boolean,
			default: false
		},
		visible: {
			type: Boolean,
			default: true
		},
	},
});
</script>
