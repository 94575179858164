<template>
	<header class="w-full">
		<div class="relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-800">
			<div class="flex justify-between flex-1 px-4 sm:px-6">
				<div class="flex items-center justify-between flex-1">
					<div class="relative w-full text-gray-400 focus-within:text-gray-600 dark:focus-within:text-gray-300">
						<div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
							<SearchIcon class="flex-shrink-0 w-5 h-5" />
						</div>
						<input
							class="hidden w-full h-full py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:block dark:bg-gray-800 dark:text-white"
							placeholder="Search all files"
							type="search"
						>
					</div>
					<EbUserMenu />
				</div>
			</div>
		</div>
	</header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbHeader
});
</script>
