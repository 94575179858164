/* eslint-disable brace-style */
import { DropdownOption } from "@/components/eb-thumbnail-card/eb-thumbnail-card-types";
import { FirebaseFile, getAllProjectsAsync, getLocalDataForFile, deleteFirebaseFileAsync } from "@/data/providers/files-provider";
import { state } from "@/data/providers/global-provider";
import { getTranslatedText } from "@/data/providers/localization-provider";
import router from "@/router";
import { reactive, toRaw } from "vue";
import { xmlCode } from "../editor/editor-state";
import { ProjectsState } from "./projects-state";

export class ProjectsModel {
	// State for Projects View
	public state: ProjectsState = reactive(new ProjectsState());

	/**
	 * Get Translated text for the projects view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("projects", key);
	}

	/**
	 * Initalize projects view
	 */
	public init(): void {
		this.getAllFilesFromFirebase();
	}

	/**
	 * Open a project in the editor
	 */
	public openProject(project: FirebaseFile): void {
		fetch(project.downloadURL).then((response: Response) => {
			return response.blob();
		}).then((blob: Blob) => {
			blob.text().then((xml: string) => {
				xmlCode.value = xml;
				state.filename = getLocalDataForFile(project).shortTitle;
				state.platform = getLocalDataForFile(project).platform;
				router.push({path: "/editor"});
			});
		});
	}

	/**
	 * Get all projects from firebase and push to view state
	 */
	public getAllFilesFromFirebase(): void {
		this.state.projects = [];
		this.state.isBusy = true;
		getAllProjectsAsync().then((files: firebase.default.storage.ListResult | undefined) => {
			if (files) {
				files.items.forEach((ref: firebase.default.storage.Reference) => {
					ref.getDownloadURL().then((url: string) => {
						ref.getMetadata().then((meta: firebase.default.storage.FullMetadata) => {
							this.state.projects.push({label: ref.name, ref: toRaw(ref), downloadURL: url, metadata: meta});
							this.state.isBusy = false;
						});
					});
				});
			}
		});
	}

	public projectDropdown(project: FirebaseFile): Array<DropdownOption> {
		return [ 
			{title: this.getText("open"), icon: "FolderOpenIcon", action: (): void => { this.openProject(project); }},
			{title: this.getText("delete"), icon: "TrashIcon", action: (): void => { deleteFirebaseFileAsync(project.ref).then(() => { this.getAllFilesFromFirebase(); }); }},
		];
	}
} 