<template>
	<div class="flex-none w-24 h-screen overflow-y-auto bg-navy-500 dark:bg-gray-800">
		<div class="flex flex-col items-center w-full py-6">
			<div class="flex items-center flex-shrink-0">
				<img
					class="w-auto h-9"
					src="/assets/images/small-logo.svg"
					alt="Workflow"
				>
			</div>
			<div class="flex-1 w-full px-2 mt-6 space-y-1">
				<a
					v-for="option in options"
					:key="option"
					class="flex flex-col items-center w-full p-3 text-xs font-medium transition-all rounded-md"
					:class="option.key === this.$route.meta.key ? 'bg-navy-700 text-white cursor-default dark:bg-gray-900' : 'group hover:bg-navy-800 hover:text-white text-navy-100 cursor-pointer dark:text-gray-400'"
					@click="this.$router.push({path: option.path})"
				>
					<component
						:is="option.icon"
						class="w-6 h-6"
						:class="option.key === this.$route.meta.key ? 'text-white' : 'text-navy-300 group-hover:text-white dark:text-gray-500'"
					/>
					<span class="mt-2">{{ component.getText(option.key) }}</span>
				</a>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { NavigationItem } from "@/data/providers/navigation-provider";
import { EbNav } from "./eb-nav";

export default defineComponent({
	name: ComponentName.EbNav,
	props: {
		options: {
			type: Object as () => NavigationItem
		}
	},
	setup() {
		const component: EbNav = new EbNav();

		return { component };
	}
});
</script>
