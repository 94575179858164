import { Platform } from "@/data/providers/platform-provider";
import { EditorButtons } from "@/views/editor/editor-type";

export const microbit: Platform = {
	name: "micro:bit",
	key: "microbit",
	image: "/assets/images/platforms/microbit.png",
	icon: "/assets/images/platforms/microbit-icon.svg",
	color: "green",
	actions: [EditorButtons.Save, EditorButtons.DownloadHex]
};